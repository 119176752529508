import { Link } from "@remix-run/react";
import React from "react";
import { FaCheck, FaMinus } from "react-icons/fa";
import { LoginButton } from "~/auth/routes/login.route";
import { useCurrentUser } from "~/auth/useCurrentUser";
import { PricingCTAButton } from "~/features/subscriptions/components/PricingCTAButton";
import { subscriptionPlans } from "~/features/subscriptions/subscriptionPlans";
import { getUsageLimitRules } from "~/features/subscriptions/usageRules";
import { cn } from "~/toolkit/components/utils";

const rules = getUsageLimitRules();

type Feature = {
  name: string;
  description: string;
  plans: {
    free: string | boolean;
    basic: string | boolean;
    pro: string | boolean;
  };
};

const getFeaturesFromUsageRules = (): Feature[] => {
  // we want a uniqe list of features based on the distinct actions from the usage rules
  // the feature name should be the usage limit title
  // the plans should be the usage limit limit
  // the plans should be a string if the limit is a number

  const distinctFeatureNames: string[] = rules.reduce((acc: any, rule) => {
    if (!acc.includes(rule.title)) {
      acc.push(rule.title);
    }
    return acc;
  }, []);

  const features: Feature[] = distinctFeatureNames.map((featureName) => {
    const featureRules = rules.filter((rule) => rule.title === featureName);
    const plans = featureRules.reduce((acc: any, rule) => {
      acc[rule.plan] = rule.limit + "";
      return acc;
    }, {});
    return {
      name: featureName,
      description: featureRules?.[0]?.description,
      plans,
    };
  });

  return features;
};

const sections = [
  {
    name: "Features",
    features: [
      ...getFeaturesFromUsageRules(),

      // TODO: add this in with enterprise plan
      // {
      //   name: "Privacy",
      //   plans: { free: false, basic: false, pro: true },
      // },
    ],
  },
];

export default function PricingTable({ hideHeading = false }) {
  let currentUser = useCurrentUser();

  return (
    <div className="px-6 py-6 bg-white">
      <div className="px-6 mx-auto max-w-7xl lg:px-8">
        {!hideHeading && (
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-2xl font-semibold leading-7 text-primary">
              Pricing
            </h2>
            <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Plans for Everyone
            </p>
          </div>
        )}

        {/* xs to lg */}
        <div className="max-w-md mx-auto mt-12 space-y-8 sm:mt-16 lg:hidden">
          {subscriptionPlans.map((plan) => (
            <section
              key={plan.id}
              className={cn(
                !currentUser && plan.mostPopular
                  ? "rounded-xl bg-gray-400/5 ring-1 ring-inset ring-gray-200"
                  : currentUser && currentUser.plan === plan.id
                  ? "rounded-xl bg-blue-50 ring-2 ring-inset ring-primary !pt-20 overflow-hidden"
                  : "",
                "p-8 relative"
              )}
            >
              {currentUser && currentUser.plan === plan.id && (
                <div className="absolute inset-x-0 top-0 p-2 text-lg font-bold text-center text-white bg-primary">
                  Current Plan
                </div>
              )}
              <h3
                id={plan.id}
                className="text-3xl font-semibold leading-6 text-gray-900"
              >
                {plan.name}
              </h3>
              <p className="mt-3 text-sm font-normal leading-6 text-gray-600">
                {plan.description}
                {plan.name === "Free" && (
                  <strong className="block">No credit card required.</strong>
                )}
              </p>
              <p className="flex items-baseline mt-3 mb-8 text-gray-900 gap-x-1">
                {plan.priceMonthly !== "FREE" && (
                  <>
                    <span className="text-4xl font-bold">
                      {plan.priceMonthly}
                    </span>
                    <span className="text-sm font-semibold">/month</span>
                  </>
                )}
              </p>
              <PricingCTAButton planId={plan.id} />
              <ul
                role="list"
                className="mt-10 space-y-4 text-sm leading-6 text-gray-900"
              >
                {sections.map((section) => (
                  <li key={section.name}>
                    <ul role="list" className="space-y-4">
                      {section.features.map((feature: any) =>
                        feature.plans[plan.id] ? (
                          <li
                            key={feature.name}
                            className="grid grid-cols-[auto_1fr] gap-4 items-center"
                          >
                            <FaCheck
                              className="flex-none w-5 h-6 text-primary"
                              aria-hidden="true"
                            />
                            <div>
                              <div className="flex items-center gap-x-2">
                                <span>{feature.name}</span>
                                {typeof feature.plans[plan.id] === "string" ? (
                                  <div className="flex items-center text-xl leading-6 text-gray-500">
                                    (
                                    <p className="flex items-baseline gap-x-1">
                                      <span className="text-xl font-bold tracking-tight text-gray-900">
                                        {feature.plans[plan.id]}
                                      </span>
                                      <span className="text-xs font-semibold leading-6 text-gray-600">
                                        /month
                                      </span>
                                    </p>
                                    )
                                  </div>
                                ) : null}
                              </div>
                              <p className="text-sm text-gray-500">
                                {feature.description}
                              </p>
                            </div>
                          </li>
                        ) : null
                      )}
                    </ul>
                  </li>
                ))}
              </ul>
            </section>
          ))}
        </div>

        {/* lg+ */}
        <div className="hidden mt-20 isolate lg:block">
          <div className="relative -mx-8">
            {/* MOST POPULAR STYLING */}
            {!currentUser &&
            subscriptionPlans.some((plan) => plan.mostPopular) ? (
              <div className="absolute inset-y-0 flex inset-x-4 -z-10">
                <div
                  className="flex w-1/4 px-4"
                  aria-hidden="true"
                  style={{
                    marginLeft: `${
                      (subscriptionPlans.findIndex((plan) => plan.mostPopular) +
                        1) *
                      25
                    }%`,
                  }}
                >
                  <div className="w-full border-t rounded-t-xl border-x border-gray-900/10 bg-gray-400/5" />
                </div>
              </div>
            ) : currentUser ? (
              <div className="absolute inset-y-0 flex inset-x-4 -z-10">
                <div
                  className="flex w-1/4 px-4"
                  aria-hidden="true"
                  style={{
                    marginLeft: `${
                      (subscriptionPlans.findIndex(
                        (plan) => plan.id === currentUser?.plan
                      ) +
                        1) *
                      25
                    }%`,
                  }}
                >
                  <div className="w-full border-2 border-primary bg-blue-50" />
                </div>
              </div>
            ) : null}
            <table className="w-full text-left border-separate table-fixed border-spacing-x-8">
              <caption className="sr-only">Pricing plan comparison</caption>
              <colgroup>
                <col className="w-1/4" />
                <col className="w-1/4" />
                <col className="w-1/4" />
                <col className="w-1/4" />
              </colgroup>
              <thead>
                <tr>
                  <td />
                  {subscriptionPlans.map((plan) => (
                    <th
                      key={plan.id}
                      scope="col"
                      className="relative px-6 pt-6 align-text-top xl:px-8 xl:pt-8"
                    >
                      {/* Show label if currentUser.plan is the same as plan.id */}
                      {currentUser?.plan === plan.id && (
                        <div className="absolute inset-x-0 top-0 p-2 text-center text-white -translate-y-full bg-primary rounded-t-xl">
                          Current Plan
                        </div>
                      )}
                      <div className="flex flex-col items-start justify-start">
                        <div className="text-sm font-semibold leading-7 text-gray-900">
                          {plan.name}
                        </div>
                        <p className="mt-3 text-sm font-normal leading-6 text-gray-600">
                          {plan.description}
                          {plan.name === "Free" && (
                            <strong className="block">
                              No credit card required.
                            </strong>
                          )}
                        </p>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <span className="sr-only">Price</span>
                  </th>
                  {subscriptionPlans.map((plan) => (
                    <td key={plan.id} className="px-6 pt-2 xl:px-8">
                      <div className="flex items-baseline mt-2 mb-8 text-gray-900 gap-x-1">
                        <span className="text-4xl font-bold">
                          {plan.priceMonthly}
                        </span>
                        {plan.priceMonthly !== "FREE" && (
                          <span className="text-sm font-semibold leading-6">
                            /month
                          </span>
                        )}
                      </div>
                      <PricingCTAButton planId={plan.id} />
                      <div className="text-xl font-bold text-center text-gray-600 mt-7">
                        Includes
                      </div>
                    </td>
                  ))}
                </tr>
                {sections.map((section, sectionIdx) => (
                  <React.Fragment key={section.name}>
                    {section.features.map((feature: any) => (
                      <tr key={feature.name}>
                        <th
                          scope="row"
                          className="py-4 font-normal leading-6 text-gray-900 text-md"
                        >
                          {feature.name}
                          <p className="text-sm text-gray-500">
                            {feature.description}
                          </p>
                          <div className="absolute h-px mt-4 inset-x-8 bg-gray-900/5" />
                        </th>
                        {subscriptionPlans.map((plan) => (
                          <td key={plan.id} className="px-6 py-4 xl:px-8">
                            {typeof feature.plans[plan.id] === "string" ? (
                              <div className="flex items-center justify-center text-sm leading-6 text-center text-gray-500 gap-x-3">
                                <FaCheck
                                  className="w-5 h-5 text-primary"
                                  aria-hidden="true"
                                />
                                <p className="flex items-baseline gap-x-1">
                                  <span className="text-xl font-bold tracking-tight text-gray-900">
                                    {feature.plans[plan.id]}
                                  </span>
                                  <span className="text-xs font-semibold leading-6 text-gray-600">
                                    /month
                                  </span>
                                </p>
                              </div>
                            ) : (
                              <>
                                {feature.plans[plan.id] === true ? (
                                  <FaCheck
                                    className="w-5 h-5 mx-auto text-primary"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <FaMinus
                                    className="w-5 h-5 mx-auto text-gray-400"
                                    aria-hidden="true"
                                  />
                                )}

                                <span className="sr-only">
                                  {feature.plans[plan.id] === true
                                    ? "Included"
                                    : "Not included"}{" "}
                                  in {plan.name}
                                </span>
                              </>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
